import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import Button from 'components/Button';
import AlertBanner from '../../notification/Alert/Banner/Banner';
import actions from './actions';
import s from './CookieNotice.less';

class CookieNotice extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.acceptCookies = this.acceptCookies.bind(this);
  }

  componentDidMount() {
    const { get } = this.props;
    get();
  }

  acceptCookies() {
    const { accept } = this.props;
    accept();
  }

  render() {
    const { cookiesAccepted } = this.props;
    if (cookiesAccepted) return '';

    return (
      <div className={s.root}>
        <AlertBanner info>
          <p>
            We use cookies for authentication and to improve your experience. By
            continuing to use this website or closing this banner, you accept
            our use of first and third-party cookies{' '}
            <a
              style={{ color: '#fff', textDecoration: 'underline' }}
              href="https://monerium.com/policies/cookie-policy/"
              target="_blank"
              rel="noreferrer"
            >
              Learn more
            </a>
            .
          </p>
          <div className="container">
            <div className="row justify-content-center">
              <Button
                medium
                success
                style={{ margin: 0 }}
                className="col-md-4"
                onClick={this.acceptCookies}
              >
                Accept cookies
              </Button>
            </div>
          </div>
        </AlertBanner>
      </div>
    );
  }
}

CookieNotice.propTypes = {
  get: PropTypes.func.isRequired,
  accept: PropTypes.func.isRequired,
  cookiesAccepted: PropTypes.bool.isRequired,
};

const mapState = ({
  policies: {
    cookieNotice: { cookiesEnabled, cookiesAccepted },
  },
}) => ({
  cookiesEnabled,
  cookiesAccepted,
});

const mapDispatch = (dispatch) => ({
  test: () => dispatch(actions.test()),
  get: () => dispatch(actions.get()),
  accept: () => dispatch(actions.accept()),
});

export default connect(mapState, mapDispatch)(withStyles(s)(CookieNotice));
