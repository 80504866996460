// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../.yarn/__virtual__/css-loader-virtual-215b868d6c/0/cache/css-loader-npm-6.7.1-b93a2de0d4-170fdbc630.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../.yarn/__virtual__/css-loader-virtual-215b868d6c/0/cache/css-loader-npm-6.7.1-b93a2de0d4-170fdbc630.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".a7Bm2{bottom:0;left:0;position:fixed;right:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "a7Bm2"
};
module.exports = ___CSS_LOADER_EXPORT___;
