// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../.yarn/__virtual__/css-loader-virtual-215b868d6c/0/cache/css-loader-npm-6.7.1-b93a2de0d4-170fdbc630.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../.yarn/__virtual__/css-loader-virtual-215b868d6c/0/cache/css-loader-npm-6.7.1-b93a2de0d4-170fdbc630.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EeHys{background-color:var(--info-background-color);color:var(--info-color);font-size:16px;font-size:1rem;font-weight:300;margin:0;padding:12px 15px;text-align:center;width:100%}.EeHys p{margin-bottom:.4375rem}.FKgC7{background-color:var(--success-color);color:var(--success-color)}.uaSYn{background-color:var(--info-background-color);color:var(--info-color)}.n19vT{background-color:var(--warning-background-color);color:var(--warning-color)}.LnnoA{background-color:var(--danger-background-color);color:var(--danger-color)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "EeHys",
	"success": "FKgC7",
	"info": "uaSYn",
	"warning": "n19vT",
	"danger": "LnnoA"
};
module.exports = ___CSS_LOADER_EXPORT___;
