import React, { FC, useContext, useEffect, useState } from 'react';
import { connect as conn } from 'react-redux';
import { animated } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import cx from 'classnames';
import useStyles from 'isomorphic-style-loader/useStyles';
import LayoutCss from 'src/components/LayoutCss/LayoutCss';
import type { ReduxStore } from 'src/customer/types';
import AppContext from 'customer/context';
import ConfirmPage from '../iam/Confirm/ConfirmPage';
import actions from '../iam/Login/actions';
import LoginPage from '../iam/Login/LoginPage';
import { AuthContext } from '../iam/Login/types';
import Notifier from '../notification/Alert/Notifier/Notifier';
import Header from './Header';
import Sidebar from './Sidebar/Sidebar';
import s from './Layout.less';

/**
 * Determines if the login or confirmation screens should be shown
 */
export const getShow = ({
  authContext,
  isProtected,
  isLoginRequest,
  isLoginSuccess,
  isLoginFailure,
}: {
  authContext?: AuthContext;
  isProtected?: boolean;
  isLoginRequest?: boolean;
  isLoginSuccess?: boolean;
  isLoginFailure?: boolean;
}) => {
  const ret: {
    showLogin?: boolean;
    showConfirm?: boolean;
  } = {
    showLogin: undefined,
    showConfirm: undefined,
  };

  if (isLoginRequest) return ret;

  if (isProtected && (isLoginSuccess || isLoginFailure)) {
    if (!authContext) {
      ret.showLogin = true;
      ret.showConfirm = false;
    } else if (!authContext.auth?.verified) {
      ret.showLogin = false;
      ret.showConfirm = true;
    } else {
      ret.showLogin = false;
      ret.showConfirm = false;
    }
  } else {
    ret.showLogin = false;
    ret.showConfirm = false;
  }

  return ret;
};

const Layout: FC<{
  authContext?: AuthContext;
  isProtected?: boolean;
  isLoginRequest?: boolean;
  isLoginSuccess?: boolean;
  isLoginFailure?: boolean;
  getAuthContext: () => Promise<AuthContext | void>;
}> = ({
  authContext,
  isProtected,
  children,
  getAuthContext,
  isLoginRequest,
  isLoginSuccess,
  isLoginFailure,
}) => {
  useStyles(s);
  const { pathname } = useContext(AppContext);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const isVerified = !!authContext?.auth?.verified;

  const bind = useDrag(({ swipe }) => {
    if (swipe[0] < 0) setShowSidebar(false); // swipe left
  });

  useEffect(() => {
    if (!authContext && !isLoginRequest) {
      getAuthContext().catch(() => {
        // not authenticated
      });
    }
  }, [isProtected]);

  useEffect(() => {
    const v = getShow({
      authContext,
      isProtected,
      isLoginRequest,
      isLoginSuccess,
      isLoginFailure,
    });
    if (v.showLogin !== undefined && v.showLogin !== showLogin)
      setShowLogin(v.showLogin);
    if (v.showConfirm !== undefined && v.showConfirm !== showConfirm)
      setShowConfirm(v.showConfirm);
  }, [isProtected, authContext, pathname, isLoginSuccess, isLoginFailure]);

  return (
    <LayoutCss>
      <div className={s.root}>
        <nav className={s.sidebar}>
          <Sidebar isVerified={isVerified} setShowSidebar={setShowSidebar} />
        </nav>
        <div className={s.container}>
          <Header setShowSidebar={setShowSidebar} />
          <div className={cx('container', s.contentContainer)}>
            {showLogin && <LoginPage redirectUri="" />}
            {showConfirm && <ConfirmPage redirect={false} />}
            {((isProtected && isVerified) || !isProtected) &&
              !showLogin &&
              !showConfirm &&
              children}
          </div>
        </div>
        <button
          type="button"
          className={cx('btn-reset', s.overlay, {
            [s.overlayActive]: showSidebar,
          })}
          onClick={() => {
            setShowSidebar(false);
          }}
          aria-label="close sidebar"
        />
        <animated.nav
          {...bind()}
          className={cx(s.sidebarFixed, {
            [s.sidebarActive]: showSidebar,
          })}
        >
          <div className={s.sidebarFixedContainer}>
            <Sidebar isVerified={isVerified} setShowSidebar={setShowSidebar} />
            <button
              type="button"
              className={s.closeSidebarBtn}
              onClick={() => {
                setShowSidebar(false);
              }}
            >
              <span className="material-icons">close</span>
            </button>
          </div>
        </animated.nav>
        <Notifier />
      </div>
    </LayoutCss>
  );
};

const ms = ({ iam }: ReduxStore) => ({
  authContext: iam?.login?.auth,
  isLoginRequest: !!iam?.login?.isLoginRequest,
  isLoginSuccess: !!iam?.login?.isLoginSuccess,
  isLoginFailure: !!iam?.login?.isLoginFailure,
});

const md = {
  getAuthContext: actions.authContext,
};

export default conn(ms, md)(Layout);
