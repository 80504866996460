// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../.yarn/__virtual__/css-loader-virtual-215b868d6c/0/cache/css-loader-npm-6.7.1-b93a2de0d4-170fdbc630.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../.yarn/__virtual__/css-loader-virtual-215b868d6c/0/cache/css-loader-npm-6.7.1-b93a2de0d4-170fdbc630.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._84WmZ{-ms-flex-pack:justify;-ms-flex-flow:column;flex-flow:column;height:100%;justify-content:space-between;position:fixed;width:250px}.Zg45c,._84WmZ{display:-ms-flexbox;display:flex}.Zg45c{-ms-flex-align:center;align-items:center;padding:30px}.Zg45c>span{color:var(--font-color-light);font-size:14.4px;font-size:.9rem;font-weight:300}.aSdRi{margin-right:15px}.Zsejs{color:#3a4759;cursor:pointer;display:block;padding:0 20px}._ibQQ{-ms-flex-align:center;align-items:center;border-radius:8px;color:var(--brand-color-darkgray);display:-ms-flexbox;display:flex;font-size:16px;font-size:1rem;font-weight:400;letter-spacing:.32px;letter-spacing:.02rem;padding:15px}._ibQQ i{color:#bbbfc5;font-size:21.6px;font-size:1.35rem;margin-right:18px}._ibQQ .NwAa7{color:var(--brand-color-darkgray);font-size:16px;font-size:1rem;margin-left:10px}.Zsejs.hbSvD,.Zsejs:focus,.Zsejs:hover{border:none;-webkit-box-shadow:transparent;box-shadow:transparent;outline:0}.Zsejs.hbSvD ._ibQQ,.Zsejs:focus ._ibQQ,.Zsejs:hover ._ibQQ{color:var(--brand-color-darkgray)}.Zsejs.hbSvD ._ibQQ i,.Zsejs:focus ._ibQQ i,.Zsejs:hover ._ibQQ i{color:#4185ce}.Zsejs.hbSvD ._ibQQ,.Zsejs.hbSvD ._ibQQ a,.Zsejs:focus ._ibQQ a,.Zsejs:hover ._ibQQ a{color:var(--brand-color-darkgray)}.Zsejs.hbSvD ._ibQQ{background:#007bff17}.dhkHi,.U96gU{display:block}.dhkHi{padding-bottom:60px}@media (max-width:990px){._84WmZ{width:200px}}@media (max-height:500px){._84WmZ{-ms-flex-pack:start;justify-content:start;position:relative}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_84WmZ",
	"homeBtn": "Zg45c",
	"homeIcon": "aSdRi",
	"link": "Zsejs",
	"linkContainer": "_ibQQ",
	"external": "NwAa7",
	"active": "hbSvD",
	"bottomMenu": "dhkHi",
	"topMenu": "U96gU"
};
module.exports = ___CSS_LOADER_EXPORT___;
